import React from 'react';
import './BlogDetail.css';

const CardiovascularHealthHeading = () => {
  return (
    <div>
      <div className='heading-style heading-none-text'>
        <span className='heading-text-style'>
          Semaglutide for Cardiovascular Health
        </span>{' '}
        <br />
        <span className='heading-text-style'>comprehensive guide</span>
      </div>
      <div className='blog-author-container'>
        <div className='author-title'>By OTC-BODY</div>
        <div className='author-title'>Sep 25, 2024</div>
      </div>
    </div>
  );
};
export default CardiovascularHealthHeading;
