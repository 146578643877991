import React from 'react';
import { Glp } from '../helper/assets';
import { useNavigate } from 'react-router';
import { blogSix } from '../helper/content';
import ConclusionCard from './Blog/BlogDetail/ConclusionCard';
import ClinicInfo from './Blog/BlogDetail/Clinic';
import CardiovascularHealthHeading from './Blog/BlogDetail/CardiovascularHealthHeading';

const BlogSection = ({ heading, paragraphs }) => (
  <>
    <h2 className='main-section-heading'>{heading}</h2>
    {Array.isArray(paragraphs) ? (
      paragraphs.map((section, index) => (
        <div key={index} className='semagluted-margin'>
          <h2 className='head-style'>{section.heading}</h2>
          <p className='head-text'>
            {Array.isArray(section.paragraph)
              ? section.paragraph.map((para, i) => <li key={i}>{para}</li>)
              : section.paragraph}
          </p>
        </div>
      ))
    ) : (
      <p className='head-text'>{paragraphs}</p>
    )}
  </>
);

const CardiovascularHealthBlog = () => {
  const {
    intro = [],
    sections = [],
    Benefits = [],
    Semaglutides_Role = [],
    Effects_Risks = [],
    Other_Treatments = [],
    Practical_Considerations = [],
    Future = [],
    Expert_Insights = [],
    Blood_Sugar = [],
    Conclusion = [],
    FAQs = [],
  } = blogSix;

  const navigate = useNavigate();

  const handleBackBlogList = () => {
    navigate('/blog');
  };

  return (
    <div className='responsive-container'>
      <div className='container-padding'>
        <div className='discover-container'>
          <img src={Glp} alt='glp' className='blog-glp-img' />

          <CardiovascularHealthHeading />
        </div>

        {intro.map((item, index) => (
          <div key={index} className='semagluted-margin'>
            <ClinicInfo title={item.title} desc={item.description} />
          </div>
        ))}

        <BlogSection
          heading=' Cardiovascular Disease: A Growing Concern'
          paragraphs={sections}
        />
        <BlogSection
          heading=' Semaglutide Role in Cardiovascular Health'
          paragraphs={Semaglutides_Role}
        />
        <BlogSection
          heading='Benefits of Semaglutide for Cardiovascular Health'
          paragraphs={Benefits}
        />
        <BlogSection
          heading='Potential Side Effects and Risks'
          paragraphs={Effects_Risks}
        />
        <BlogSection
          heading='Comparing Semaglutide with Other Treatments'
          paragraphs={Other_Treatments}
        />
        <BlogSection
          heading='Practical Considerations for Patients'
          paragraphs={Practical_Considerations}
        />
        <BlogSection heading='FAQs' paragraphs={FAQs} />
        <div className='margin-from-top'>
          {Conclusion.map((item, index) => (
            <ConclusionCard title={item.heading}>
              <p>{item.paragraph}</p>
            </ConclusionCard>
          ))}
        </div>

        <div
          onClick={handleBackBlogList}
          className='blog-button padding-from-bottom'
        >
          Back to Blog List
        </div>
      </div>
    </div>
  );
};

export default CardiovascularHealthBlog;
