import React from 'react';
import { Glp } from '../helper/assets';
import { useNavigate } from 'react-router';
import { blogFive } from '../helper/content';
import RoleOfSamaglutideHeading from './Blog/BlogDetail/RoleOfSamaglutideHeading';
import ConclusionCard from './Blog/BlogDetail/ConclusionCard';

const BlogSection = ({ heading, paragraphs }) => (
  <>
    <h2 className='main-section-heading'>{heading}</h2>
    {Array.isArray(paragraphs) ? (
      paragraphs.map((section, index) => (
        <div key={index} className='semagluted-margin'>
          <h2 className='head-style'>{section.heading}</h2>
          <p className='head-text'>
            {Array.isArray(section.paragraph)
              ? section.paragraph.map((para, i) => <li key={i}>{para}</li>)
              : section.paragraph}
          </p>
        </div>
      ))
    ) : (
      <p className='head-text'>{paragraphs}</p>
    )}
  </>
);

const RoleOfSamaglutideBlog = () => {
  const {
    sections = [],
    Benefits = [],
    Semaglutide_overview = [],
    Effects_Risks = [],
    Diabetes_Medications = [],
    Weight_Management = [],
    Practical_Considerations = [],
    Future = [],
    Expert_Insights = [],
    Blood_Sugar = [],
    Conclusion = [],
    FAQs = [],
  } = blogFive;

  const navigate = useNavigate();

  const handleBackBlogList = () => {
    navigate('/blog');
  };

  return (
    <div className='responsive-container'>
      <div className='container-padding'>
        <div className='discover-container'>
          <img src={Glp} alt='glp' className='blog-glp-img' />

          <RoleOfSamaglutideHeading />
        </div>

        <BlogSection
          heading=' The Role of Semaglutide in Blood Sugar Control'
          paragraphs={sections}
        />
        <BlogSection
          heading='  Benefits of Semaglutide'
          paragraphs={Benefits}
        />
        <BlogSection
          heading='Clinical Studies and Evidence'
          paragraphs={Semaglutide_overview}
        />
        <BlogSection
          heading='Potential Side Effects and Risks'
          paragraphs={Effects_Risks}
        />
        <BlogSection
          heading='Comparison with Other Diabetes Medications'
          paragraphs={Diabetes_Medications}
        />
        <BlogSection
          heading='Semaglutide and Weight Management'
          paragraphs={Weight_Management}
        />
        <BlogSection
          heading='Practical Considerations for Patients'
          paragraphs={Practical_Considerations}
        />
        <BlogSection heading=' The Future of Semaglutide' paragraphs={Future} />
        <BlogSection heading=' Expert Insights' paragraphs={Expert_Insights} />
        <BlogSection
          heading='Practical Tips for Managing Blood Sugar'
          paragraphs={Blood_Sugar}
        />

        <BlogSection heading='FAQs' paragraphs={FAQs} />
        <div className='margin-from-top'>
          {Conclusion.map((item, index) => (
            <ConclusionCard title={item.heading}>
              <p>{item.paragraph}</p>
            </ConclusionCard>
          ))}
        </div>

        <div
          onClick={handleBackBlogList}
          className='blog-button padding-from-bottom'
        >
          Back to Blog List
        </div>
      </div>
    </div>
  );
};

export default RoleOfSamaglutideBlog;
